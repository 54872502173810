import { Grid, Typography } from "@material-ui/core";
import { motion } from "framer-motion";
import React from 'react'
import { useStyles } from "./styles";
export function Features() {
	const classes = useStyles()
	return (
		<Grid
			container
			className={classes.root}
			initial={{ opacity: 0, x: 100 }}
			transition={{ duration: 1.1, }}
			whileInView={{ opacity: 1, x: 0 }}
			viewport={{ once: true }}
			component={motion.div}
		>
			<Grid item>
				<Typography variant="h2" className={classes.title}>
					Características de tu Crédito Hipotecario
				</Typography>
			</Grid>
			<Grid container className={classes.itemsContainer}>
				<Grid item className={classes.item}>
					<img
						loading="lazy"
						width="116"
						height="100"
						src="/assets/images/landing/aforo.svg"
						alt="Te prestamos hasta el 90% de Aforo del valor de tu próxima casa o departamento."
					/>
					<Typography>Te prestamos hasta el 90% de Aforo del valor de tu próxima casa o departamento.</Typography>
				</Grid>
				<Grid item className={classes.item}>
					<img
						loading="lazy"
						width="116"
						height="100"
						src="/assets/images/landing/credito_desde.svg"
						alt="Tu Crédito desde $600,000 MXN"
					/>
					<Typography>Tu Crédito desde $600,000 MXN</Typography>
				</Grid>
				<Grid item className={classes.item}>
					<img
						loading="lazy"
						width="116"
						height="100"
						src="/assets/images/landing/a_tu_ritmo.svg"
						alt="Hazlo a tu ritmo, con plazos desde 5 hasta 20 años."
					/>
					<Typography>Hazlo a tu ritmo, con plazos desde 5 hasta 20 años.</Typography>
				</Grid>
				<Grid item className={classes.item}>
					<img
						loading="lazy"
						width="116"
						height="100"
						src="/assets/images/landing/proceso_linea.svg"
						alt="Toma el control, con un proceso en línea."
					/>
					<Typography>Toma el control, con un proceso en línea.</Typography>
				</Grid>
				<Grid item className={classes.item}>
					<img
						loading="lazy"
						width="116"
						height="100"
						src="/assets/images/landing/participantes.svg"
						alt="Suma ingresos y obtén más crédito"
					/>
					<Typography>Suma ingresos y obtén más crédito.</Typography>
				</Grid>
			</Grid>
		</Grid>
	)
}