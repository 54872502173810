import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: '72px 0px 145px',
		display: 'grid',
		gridTemplateColumns: '60% 40%',
		[theme.breakpoints.down('sm')]: {
			padding: '0px',
			display: 'flex',
			flexDirection: 'column-reverse'

		}
	},
	titleContainer: {
		paddingRight: '140px',
		[theme.breakpoints.down('sm')]: {
			padding: '0px',
			marginTop: 60,
			marginBottom: 60,
			maxWidth: '100%'
		}

	},
	title: {
		fontSize: 60,
		lineHeight: '76px',
		fontWeight: 900,
		color: theme.palette.primary.main,
		marginBottom: 16,
		[theme.breakpoints.down('sm')]: {
			fontSize: 28,
			lineHeight: '34px',
			textAlign: 'center'
		},
		['@media (min-width: 1920px)']: {
			fontSize: 62,
			lineHeight: '72px',
		},
		['@media (min-width: 2160px)']: {
			fontSize: 100,
			lineHeight: '110px',
		}
	},
	subtitle: {
		fontSize: 32,
		lineHeight: '44px',
		letterSpacing: '-0.011em',
		[theme.breakpoints.down('sm')]: {
			fontSize: 18,
			lineHeight: '28px',
			textAlign: 'center',
			letterSpacing: '0.18px',
		},
		['@media (min-width: 1920px)']: {
			fontSize: 42,
			lineHeight: '52px',
		},
		['@media (min-width: 2160px)']: {
			fontSize: 52,
			lineHeight: '62px',
		}
	},
	cardContainer: {
		justifySelf: 'center',
		position: 'relative',
		height: '474px',
		width: '407px',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
			width: 'auto',
		},
	},
	card: {
		boxShadow: ' 0px 5px 22px rgba(57, 74, 97, 0.12)',
		borderRadius: 8,
		width: 407,
		height: 474,
		[theme.breakpoints.down('md')]: {
			width: "100%",

		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%'
		}
	},
	decoration1: {
		position: 'absolute',
		top: '-98px',
		transform: 'translateX(-100%)',
		left: '-34.06px',
		zIndex: 99,
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	decoration2: {
		position: 'absolute',
		top: '-98px',
		right: "-82px",
		zIndex: -1,
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	decoration21: {
		position: 'absolute',
		bottom: "-49px",
		right: "350px",
		transform: 'translateY(100%)',
		zIndex: -1,
		width: 158,
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	decoration4: {
		position: 'absolute',
		bottom: "100px",
		left: "-179px",
		transform: 'translateX(-100%)',
		zIndex: -1,
		width: 76,
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	decoration3: {
		position: 'absolute',
		right: '-113px',
		bottom: "-142px",
		zIndex: -1,
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	skeletonsContainer: {
		backgroundColor: 'white',
		boxShadow: ' 0px 5px 22px rgba(57, 74, 97, 0.12)',
		borderRadius: 8,
		padding: 24,
	},
	skeleton: {
		marginBottom: 12
	},
	displayNone: {
		display: 'none'
	}
}))