import React from 'react'
import { AppBar, Grid, Toolbar } from "@material-ui/core";
import { useStyles } from './styles';

export function Topbar() {
	const classes = useStyles()

	return (
		<>
			<AppBar
				color="inherit"
				elevation={0}
				position="absolute"
				className={classes.appBar}
			>
				<Toolbar className={classes.toolbar}>
					<Grid container>
						<img src="/assets/images/Logo.svg" alt="Kredi" />
					</Grid>
				</Toolbar>
			</AppBar>
		</>
	)
}