import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	layout: {
		padding: '0 70px',
		maxWidth: "100vw",
		overflow: 'hidden',
		[theme.breakpoints.down('sm')]: {
			padding: '0 16px',
		}
	},
	hero: {
		marginTop: 73,
		padding: '0 70px',
		maxWidth: "100vw",
		[theme.breakpoints.down('sm')]: {
			padding: '0 16px',
			marginTop: 74,
		}
	},
	opacityZero: {
		opacity: 0 + '!important',
	},
}))