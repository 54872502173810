import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'grid',
		gridTemplateColumns: '50% 50%',
		padding: '70px 0',
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: '100%',
		}
	},
	title: {
		fontWeight: 900,
		fontSize: 50,
		lineHeight: "54px",
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			fontSize: 24,
			lineHeight: "32px",
			textAlign: 'center',
			marginBottom: 16,
		}
	},
	subtitleContainer: {
		display: 'flex',
		gap: 21,
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			marginBottom: 26,
			"& img": {
				display: 'none'
			}
		}
	},
	subtitle: {
		fontSize: 32,
		lineHeight: "54px",
		[theme.breakpoints.down('sm')]: {
			fontSize: 20,
			lineHeight: "28px",
			textAlign: 'center'
		}
	},
	card: {
		boxShadow: '0px 5px 22px rgba(57, 74, 97, 0.12)',
		borderRadius: 8,
		padding: 52,
		[theme.breakpoints.down('sm')]: {
			padding: '22px'
		}
	},
	requirementsList: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		"& p": {
			fontSize: 20,
			lineHeight: "32px",
			[theme.breakpoints.down('sm')]: {
				fontSize: 16,
				lineHeight: "24px",
			}
		},
		"& p svg": {
			fontSize: 15,
			color: theme.palette.primary.main
		},
		'& a': {
			textDecoration: 'none'
		},
		'& sup': {
			color: theme.palette.primary.main,
			fontSize: 12,
			cursor: 'pointer',
			'&:hover': {
				textUnderlinePosition: 'under',
				textDecoration: 'underline'
			}
		}
	}
}))