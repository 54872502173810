import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		flexDirection: 'column',
		alignItems: 'center'
	},
	title: {
		fontWeight: 900,
		fontSize: "50px",
		lineHeight: "64px",
		color: theme.palette.primary.main,
		marginBottom: 46,
		[theme.breakpoints.down('sm')]: {
			fontSize: "24px",
			lineHeight: "32px",
			textAlign: 'center',
		}
	},
	descriptionContainer: {
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 20
		}

	},
	description: {
		fontSize: "24px",
		lineHeight: "24px",
		letterSpacing: "0.18px",
		marginBottom: 28,
		"& svg": {
			color: theme.palette.primary.main,
			fontSize: 18,
			marginRight: 8,

		},
		[theme.breakpoints.down('sm')]: {
			fontSize: "16px",
			lineHeight: "24px",
			"& svg": {
				color: theme.palette.primary.main,
				fontSize: 15,
				marginRight: 4,

			},
		}
	},

	highlightsContainer: {
		marginTop: 64,
		gap: 15,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			alignItems: 'center',
			gap: 30,
			marginTop: 32,
			marginBottom: 0,

		}
	},
	highlightsDivider: {
		width: 1,
		height: 136,
		backgroundColor: theme.palette.primary.main,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: 1,
		}
	},
	highlightsTitle: {
		fontSize: 68,
		lineHeight: "91px",
		fontWeight: "bold",
		textAlign: 'center',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			fontSize: "48px",
			lineHeight: "64px",
		}

	},
	highlightsSubtitle: {
		fontSize: 25,
		lineHeight: "46px",
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: "18px",
			lineHeight: "32px",
		}
	}
}))