import { Card, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import React from 'react'
import { Check } from "@material-ui/icons";
import { motion } from "framer-motion";
export function Requiriments() {
	const classes = useStyles()
	return (
		<Grid
			container
			className={classes.root}
			initial={{ opacity: 0, x: 100 }}
			transition={{ duration: 1.1, }}
			whileInView={{ opacity: 1, x: 0 }}
			viewport={{ once: true }}
			component={motion.div}
		>
			<Grid item>
				<Grid item>
					<Typography className={classes.title} variant="h2">Solicita ahora tu Crédito Hipotecario</Typography>
				</Grid>
				<Grid item className={classes.subtitleContainer}>
					<Typography className={classes.subtitle}>Requisitos minímos</Typography>
					<img src="/assets/images/landing/flecha.svg" alt="" />
				</Grid>
			</Grid>
			<Grid item>
				<Card className={classes.card} elevation={0}>
					<Grid container className={classes.requirementsList}>
						<Typography> <Check /> &nbsp; Ingresos comprobables netos por $18,000 MXN (Recuerda que puedes sumar a un amigo o familiar <a href="#footer"> <sup> 1 </sup> </a> ).</Typography>
						<Typography> <Check />&nbsp; Tener entre 23 y 60 años.</Typography>
						<Typography> <Check />&nbsp; Autorizar la consulta de buró de crédito.</Typography>
						<Typography> <Check />&nbsp; ¡Estar listo para estrenar tu nuevo hogar!</Typography>
					</Grid>
				</Card>
			</Grid>
		</Grid>
	)
}