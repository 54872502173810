import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	card: {
		boxShadow: ' 0px 5px 22px rgba(57, 74, 97, 0.12)',
		borderRadius: 8,
		width: 407,
		minHeight: 474,
		padding: 24,
		position: 'relative',
		[theme.breakpoints.down('md')]: {
			width: "100%",
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%',
			padding: '24px 16px'
		},
		transition: theme.transitions.create('height', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	skeleton: {
		marginBottom: 12
	}
}))