import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		justifyContent: 'center',
		padding: '64px 0',
	},
	title: {
		fontWeight: 'bold',
		fontSize: 50,
		lineHeight: "54px",
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			fontSize: 24,
			lineHeight: "32px",
		}
	},
	itemsContainer: {
		display: 'flex',
		flexDirection: 'column',

	},
	item: {
		display: 'grid',
		gridTemplateColumns: '42% 58%',
		[theme.breakpoints.down('sm')]: {
			//gridTemplateColumns: '100%',
			maxWidth: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		}

	},
	itemAlt: {
		display: 'grid',
		gridTemplateColumns: '58% 42%',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column-reverse',
			maxWidth: '100%',
			alignItems: 'center'

		}
	},
	image: {
		width: 408,
		height: 274,
		justifySelf: 'end'
	},
	imageAlt: {
		width: 408,
		height: 274,
		justifySelf: 'start'
	},
	details: {
		alignSelf: 'center',
	},
	detialsAlt: {
		[theme.breakpoints.up('lg')]: {
			justifySelf: 'end',

		}
	},
	detailsTitle: {
		fontWeight: 700,
		fontSize: 34,
		lineHeight: "36px",
		marginBottom: 16,
		[theme.breakpoints.down('sm')]: {
			fontSize: 20,
			lineHeight: "24px",
			textAlign: 'center'
		}
	},
	detailsTitleAlt: {
		[theme.breakpoints.up('lg')]: {
			maxWidth: '60%',
			margin: 'auto',
			marginBottom: 16

		}
	},
	detailsDescriptionAlt: {
		[theme.breakpoints.up('lg')]: {
			maxWidth: '60%',
			margin: 'auto'

		}
	},
	detailsDescription: {
		fontSize: 24,
		lineHeight: "44px",
		letterSpacing: "0.15px",
		[theme.breakpoints.down('sm')]: {
			fontSize: 18,
			lineHeight: "32px",
			textAlign: 'center'

		}
	}
}))