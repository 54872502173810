import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	card: {
		width: '100%',
		height: 199,
		boxShadow: '0px 5px 22px rgba(57, 74, 97, 0.12)',
		borderRadius: 8,
		padding: '29px 0',
		[theme.breakpoints.down('sm')]: {
			padding: '24px 0',
			height: 159,
		},
		[theme.breakpoints.down(357)]: {
			height: 'auto',
		}
	},
	titleContainer: {
		justifyContent: 'center',
		marginBottom: 15,

	},
	title: {
		fontSize: 24,
		lineHeight: "24px",
		textAlign: "center",
		letterSpacing: "0.18px",
		[theme.breakpoints.down('sm')]: {
			fontSize: 18,
			lineHeight: "24px",

		}
	},
	logosContainer: {
		alignItems: 'center',
		'& img': {
			display: 'block',
			margin: '0 auto'
		},
		'& > div': {
			textAlign: 'center',
			[theme.breakpoints.down('sm')]: {
				marginBottom: 16
			},
		}
	},
	cnbv: {
		width: 208,
		height: 84,
		[theme.breakpoints.down('sm')]: {
			width: 146,
			height: 60,
			marginLeft: 8
		},
	},

	condusef: {
		width: 143,
		height: 75,
		[theme.breakpoints.down('sm')]: {
			width: 97,
			height: 52,
			marginLeft: 8
		},
	},

	buro: {
		width: 77,
		height: 77,
		[theme.breakpoints.down('sm')]: {
			width: 48,
			height: 48,
			marginLeft: 18
		},
	},
}))