import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		borderTop: '1px solid #CFD5E2',
		padding: '16px 70px',
		justifyContent: 'flex-start',
		[theme.breakpoints.down('sm')]: {
			padding: '16px 16px',
		}
	},
	text: {
		fontSize: 10,
		lineHeight: "16px",
	}
}))