import { Card, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from 'react'
import { useStyles } from "./styles";
import { motion } from 'framer-motion'
import { LoadableQuotationCard } from "../QuotationCard";
import { LoadingCard } from "../QuotationCard/LoadingCard";
import clsx from "clsx";

export function Hero() {
	const classes = useStyles()
	const [isLoadingAbacus, setIsLoadingAbacus] = useState(true)

	useEffect(() => {
		setTimeout(() => {
			setIsLoadingAbacus(false)
		}, 3000)
	}, [])

	return (
		<Grid
			container
			className={classes.root}
			initial={{ opacity: 0, x: -1000 }}
			transition={{ duration: 1.42 }}
			animate={{ opacity: 1, x: 0 }}
			viewport={{ once: true }}
			component={motion.section}
		>
			<Grid item className={classes.titleContainer}>
				<Typography variant="h1" className={classes.title}>
					Crédito Hipotecario para comprar Tu Casa o Departamento
				</Typography>
				<Typography className={classes.subtitle}>
					Te prestamos desde $1,000,000, fácil
					y sin complicaciones
				</Typography>
			</Grid>
			<Grid item className={classes.cardContainer}>
				<img src="/assets/images/landing/decoration-1.svg" className={classes.decoration1} alt="" />
				<img src="/assets/images/landing/decoration-2.svg" className={classes.decoration2} alt="" />
				<img src="/assets/images/landing/decoration-2.svg" className={classes.decoration21} alt="" />
				<img src="/assets/images/landing/decoration-3.svg" className={classes.decoration3} alt="" />
				<img src="/assets/images/landing/decoration-4.svg" className={classes.decoration4} alt="" />
				<LoadingCard className={clsx({ [classes.displayNone]: !isLoadingAbacus })} />
				<div className={clsx({ [classes.displayNone]: isLoadingAbacus })} >
					<LoadableQuotationCard />
				</div>
			</Grid>
		</Grid>
	)
}