import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	appBar: {
		backgroundColor: 'transparent',
	},
	toolbar: {
		padding: '40px 67px',
		backgroundColor: 'transparent',
		[theme.breakpoints.down('sm')]: {
			padding: '16px',
			textAlign: 'center',
			"& > div": {
				justifyContent: 'center',
			},
			"& div img": {
				width: 80,
				height: 20
			}
		}
	},
	toolbarFixed: {
		padding: '20px 67px',
		[theme.breakpoints.down('sm')]: {
			padding: '15px',
			"& div": {
				justifyContent: 'space-between'
			},
			"& div img": {
				width: 80,
				height: 20
			}
		}
	}
}))