import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: '100px 0',
		width: '100%',
		justifyContent: 'center'
	},

	title: {
		fontStyle: "normal",
		fontWeight: 900,
		fontSize: 50,
		lineHeight: "54px",
		color: theme.palette.primary.main,
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: 24,
			lineHeight: "32px",
		}
	},
	itemsContainer: {
		display: 'grid',
		gridTemplateColumns: 'repeat(5, 1fr)',
		gap: 40,
		marginTop: 64,
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: '100%',

		}
	},
	item: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		"& p": {
			marginTop: 24,
			textAlign: "center"
		}
	}
}))