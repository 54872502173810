import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import React from 'react'
import { useStyles } from "./styles";
export function Steps() {
	const classes = useStyles()
	return (
		<Grid
			container
			className={classes.root}
			initial={{ opacity: 0, x: 100 }}
			transition={{ duration: 1.1, }}
			whileInView={{ opacity: 1, x: 0 }}
			viewport={{ once: true }}
			component={motion.div}
		>
			<Grid item>
				<Typography variant="h3" className={classes.title}>
					Rápido, Fácil y Sin broncas
				</Typography>
			</Grid>
			<Grid container className={classes.itemsContainer}>
				<Grid item className={classes.item}>
					<img
						className={classes.image}
						src="/assets/images/paso-1.svg"
						alt="Completa el formulario en línea"
						loading="lazy"

					/>
					<Grid item className={classes.details}>
						<Typography className={classes.detailsTitle}>Completa el formulario en línea </Typography>
						<Typography className={classes.detailsDescription}>Completa un breve formulario y recibe tu precalificación.</Typography>
					</Grid>
				</Grid>
				<Grid item className={classes.itemAlt}>
					<Grid item className={clsx(classes.details, classes.detialsAlt)}>
						<Typography className={classes.detailsTitle}>Asesoría y Documentos</Typography>
						<Typography className={classes.detailsDescription}>Recibe asesoría de nuestro ejecutivo y reúne tus documentos.</Typography>
					</Grid>
					<img
						className={classes.imageAlt}
						src="/assets/images/paso-2.svg"
						loading="lazy"

						alt="Asesoría y Documentos"
					/>
				</Grid>
				<Grid item className={classes.item}>
					<img
						className={classes.image}
						src="/assets/images/paso-3.svg"
						loading="lazy"
						alt="Autorización"
					/>
					<Grid item className={classes.details}>
						<Typography className={classes.detailsTitle}>Autorización</Typography>
						<Typography className={classes.detailsDescription}>Evaluamos tu solicitud y te damos una respuesta en menos de 48 hrs.</Typography>
					</Grid>
				</Grid>
				<Grid item className={classes.itemAlt}>
					<Grid item className={clsx(classes.details, classes.detialsAlt)}>
						<Typography className={clsx(classes.detailsTitle, classes.detailsTitleAlt)}>Valuamos y escrituramos </Typography>
						<Typography className={clsx(classes.detailsDescription, classes.detailsDescriptionAlt)}>Reunimos los documentos del inmueble, se valúa y comienza la formalización.</Typography>
					</Grid>
					<img
						className={classes.imageAlt}
						src="/assets/images/paso-4.svg"
						loading="lazy"

						alt="Valuamos y escrituramos"
					/>
				</Grid>
				<Grid item className={classes.item}>
					<img
						className={classes.image}
						src="/assets/images/paso-5.svg"
						loading="lazy"

						alt="Estrena tu hogar"
					/>
					<Grid item className={classes.details}>
						<Typography className={classes.detailsTitle}>Estrena tu hogar</Typography>
						<Typography className={classes.detailsDescription}>¡Firma y recibe tu crédito!</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}