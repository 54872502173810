import { Divider, Grid, Typography } from "@material-ui/core";
import React from 'react'
import { useStyles } from "./styles";

export function Footer() {
	const classes = useStyles()
	return (
		<>
			<Grid id="footer" container className={classes.root}>
				<Typography className={classes.text} style={{ marginBottom: 4 }}>
					© 2022 FINANCIERA INTELIGENTE, S.A. de C.V., S.O.F.O.M. E.N.R - Todos los derechos reservados
				</Typography>
				<Typography className={classes.text}>
					<strong>CAT Promedio del 10.8% sin IVA</strong> calculado al 28 de Enero del 2022. Está basado valor
					de vivienda con valor de 1 millón de pesos, tasa de interés promedio ponderado anual fija del 8.50%,
					para un plazo a 20 años, con 80% de financiamiento, una comisión por apertura del 1.0% financiada y
					representa el costo anual total del financiamiento expresado en términos porcentuales anuales incluyendo
					la totalidad de los costos promedio garantizados (hipotecario). Incluyendo seguros de vida y daños,
					comisión por apertura, por administración e intereses, excluyendo los impuestos y derechos federales
					y locales, así como, los costos correspondientes a trámites y servicios prestados por terceros. Para
					fines informativos y de comparación exclusivamente.
				</Typography>
				<Typography className={classes.text} style={{ marginTop: 16 }}>
					1 Amigo o familiar, si es como coacreditado, aval, obligado solidario
				</Typography>
			</Grid>
		</>
	)
}