import { Grid, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { motion } from "framer-motion";
import React from 'react'
import { useStyles } from "./styles";
export function WhyKredi() {
	const classes = useStyles()
	return (
		<Grid
			container
			className={classes.root}
			initial={{ opacity: 0, x: 100 }}
			transition={{ duration: 1.1, }}
			whileInView={{ opacity: 1, x: 0 }}
			viewport={{ once: true }}
			component={motion.div}
		>
			<Grid item>
				<Typography className={classes.title} variant="h2">Nuestros clientes eligen Kredi porque</Typography>
			</Grid>
			<Grid item className={classes.descriptionContainer}>
				<Typography className={classes.description}>
					<Check />
					Queremos ayudar a más familias mexicanas a tener el hogar de sus sueños.
				</Typography>
				<Typography className={classes.description}>
					<Check />
					Buscamos hacer fácil lo que siempre había sido complicado.
				</Typography>
			</Grid>
			<Grid item container justifyContent="center" className={classes.highlightsContainer}>
				<Grid item>
					<Typography className={classes.highlightsTitle}>105+</Typography>
					<Typography

						className={classes.highlightsSubtitle}>
						Familias confiaron en nosotros
					</Typography>
				</Grid>
				<div className={classes.highlightsDivider} />
				<Grid item>
					<Typography className={classes.highlightsTitle}>100+</Typography>
					<Typography

						className={classes.highlightsSubtitle}
					>
						Millones de pesos en créditos
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	)
}