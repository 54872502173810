import React from 'react'
import { Hero, Regulations, Layout, Features, Requiriments, Steps, WhyKredi, Footer } from '../../../components/Landing/HomePurchaseCredit'
import { Testimonial } from '../../../components'
export default function HomePurchaseCredit({ location }: any) {
	return (
		<>
			<Layout location={location} hero={<Hero />}>
				<Regulations />
				<Features />
				<Requiriments />
				<Steps />
				<WhyKredi />
				<Testimonial />
			</Layout>
			<Footer />
		</>
	)
}
