import { Card, Grid, Typography } from "@material-ui/core";
import React from 'react'
import { useStyles } from "./styles";
import { motion } from 'framer-motion'
export function Regulations() {
	const classes = useStyles()
	return (

		<Card
			className={classes.card}
			elevation={0}
			initial={{ opacity: 0, x: 100 }}
			transition={{ duration: 1.1, }}
			whileInView={{ opacity: 1, x: 0 }}
			viewport={{ once: true }}
			// @ts-ignore
			component={motion.div}
		>
			<Grid container>
				<Grid container className={classes.titleContainer}>
					<Typography className={classes.title}>Nuestras autoridades</Typography>
				</Grid>
				<Grid container className={classes.logosContainer} alignItems="center" justifyContent="center">
					<Grid item xs>
						<img
							className={classes.cnbv}
							src="/assets/images/landing/hero_cnbv.png"
							loading="lazy"
							alt="Logo de la Comisión Nacional Bancaria y de Valores"
						/>
					</Grid>
					<Grid item xs>
						<img
							className={classes.condusef}
							loading="lazy"
							src="/assets/images/landing/hero_condusef.png"
							alt="Logo de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros"
						/>
					</Grid>
					<Grid item xs>
						<img
							className={classes.buro}
							src="/assets/images/landing/hero_buro.png"
							loading="lazy"
							alt="Logo de Buró de Entidades Financieras"
						/>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	)
}