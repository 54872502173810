import { Grid } from "@material-ui/core";
import React, { ComponentType, ReactElement, useEffect, useState } from "react";
import { SEO } from "../../../../layout/SEO";
import { Theme } from "../../../../assets/theme";
import { Topbar } from "../Topbar";
import { useStyles } from "./styles";
import { LoadableScrollTopBar } from "../ScrollTopBar";
import clsx from "clsx";

interface LayoutProps {
	/** it was placed as prop since when placing the hero floating designs the layout styles hid them by the overflow hidden rule */
	hero?: ReactElement | ReactElement[],
	children: ReactElement | ReactElement[],
	location: any
}

const loaderStyle: React.CSSProperties = {
	backgroundColor: 'white',
	position: 'fixed',
	left: '0',
	top: '0',
	width: '100%',
	height: '100%',
	zIndex: '99999',
	transitionDuration: '500ms',
	opacity: 1,
	pointerEvents: 'none'
};

export function Layout({ children, location, hero }: LayoutProps) {
	const classes = useStyles()
	const HeroComponent = hero || React.Fragment;
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false)
		}, 400)
	}, [])

	return (
		<Theme>
			<SEO location={location} />
			<LoadableScrollTopBar />
			<Topbar />
			<div style={loaderStyle} className={clsx(!isLoading && classes.opacityZero)}></div>
			<div className={classes.hero}>
				{HeroComponent}
			</div>
			<Grid container className={classes.layout}>
				{children}
			</Grid>
		</Theme>
	)
}